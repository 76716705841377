import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <br />
    <h3>{`1. Select the right type of activity`}</h3>
    <br />
    <strong>Trail biking</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Typically a narrow path or trail less than <strong>1.5m wide</strong>. It can
  be a purpose built mountain bike track. Can be uphill, downhill or both.
  <br />
  <br />
  If the track contains <strong>short parts of gravel road</strong> - for example
  to connect two singletracks - it is up to you if you split the track into two or
  not.
  <br />
  <br />
  The ride, should be <strong>as continuous as possible</strong> without
  connecting too many individual trails.
  <br />
  <br />
  Try to <strong>limit loops</strong> to a natural ride in the particular area. A
  good example is this <Link to="https://trailguide.net/3" mdxType="Link">trail</Link> where there
  are not too many other trails in the area and it creates a natural ride on the
  mountain.
  <br />
  <br />
  If the area is <strong>very crowded</strong> with trails and loops will
  overlap, try to split them up in natural trails instead. A good example of
  this can be found{" "}
  <Link to="https://trailguide.net?pos=14,59.145,10.391" mdxType="Link">here</Link> where the
  trails are split up and you can mix and match to create a natural loop to ride
  based on your skill level.
  <br />
  <br />
  <strong>
    If the name or description is something like "loop of all the best trails in
    ..." then you are most likely doing it wrong.
  </strong>
    </div>
    <br />
    <strong>Cross-country</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Cross-country biking or now adays called <strong>gravel biking</strong>.
  <br />
  <br />
  If the tour you want to add is <strong>mostly gravel road</strong> mixed in with
  some tarmac and the occasional singletrack, then this is the right type of activity
  category.
  <br />
  <br />
  Try to avoid loops that cover other gravel rides in the area, but loops are
  very welcome as they are natural training rides.
  <br />
  <br />
  For longer tours and rides meant for tour biking, use that category instead.
    </div>
    <br />
    <strong>Road biking</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Cycling on tarmac roads. The focus is more sportive than Tour Cycling, to
  overcome a certain distance or climb.
  <br />
  <br />
  Loops and climbs are very welcome in this category. Especially great climbs. A
  nice example can be found <Link to="https://trailguide.net/x5n" mdxType="Link">here</Link>.
  <br />
  <br />
  For longer tours and rides meant for tour biking, use that category instead.
    </div>
    <br />
    <strong>Tour biking</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Focus is on being outdoors, enjoying nature, sightseeing, and easy pleasure.
  It's not about sportive performance, and accomplishing technical difficult
  routes. The bicycles are made for sealed roads. There can be parts on compact
  gravel roads or narrow tracks that have no technical challenges.
  <br />
  <br />
  Large loops are welcome in this category. An example can be found <Link to="https://trailguide.net/10936" mdxType="Link">
    here
  </Link>.
    </div>
    <br />
    <br />
    <h3>{`2. Good quality GPX file and redundancy`}</h3>
    <br />
    <strong>Quality</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Make sure you have good .gpx data. Enough data points to show a round and
  smooth line (not angled straights). No parts with signal drift or skipping. No
  parts where you took the wrong way recording it. Elevation data must be
  included to automatically generate the height profile.
    </div>
    <br />
    <strong>Redundancy</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Before you upload a trail, check if it’s already on the map. If it overlaps an
  existing trail for a short section (10%) it’s ok. Cut the trail if it overlaps
  more.
    </div>
    <br />
    <br />
    <h3>{`3. Description and details`}</h3>
    <br />
    <strong>Character</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Describe the character or the experience of the trail or route in one
  comprehensive sentence. "Intense descend in rocky and technical terrain." or
  "Easy natural trail with a stunning view into the XY valley."
    </div>
    <br />
    <strong>Details</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Roots, rock, stones, gravel, sand, gras, dirt, needles, leafs, loose, compact,
  berms, drops, flow, speed, smooth, rough, climbs, push biking, steep, flat, up
  and down, view, scenery, points of interest, forest, meadow, rock, alpine,
  river, valley, ridge, traverse, exposition, water sources, shelters, huts,
  restaurants, season, suitable weather conditions.
    </div>
    <br />
    <strong>Avoid</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Avoid describing the route, length and elevation. That's where the gps track
  and the location function is for. If needed, mention tips when it easy to make
  mistakes e.g. when the trail is close and parallel to a fireroad, and it's
  hard to distinguish it on the map.
    </div>
    <br />
    <h3>{`4. Difficulty`}</h3>
    <br />
    <p>{`For trail biking, the difficulty can be key to the joy of riding it. Our scale is based
on the technical difficulty of the trail and the imagination of having a trailer with a
sleeping kid behind your bike.`}</p>
    <br />
    <div className="my-1 p-3 bg-green-700 rounded text-white">
  <strong>Easy</strong> - the kid wakes up, but is not crying.
    </div>
    <div className="my-1 p-3 bg-blue-700 rounded text-white">
  <strong>Intermediate</strong> - the kid wakes up and starts to cry.
    </div>
    <div className="my-1 p-3 bg-red-700 rounded text-white">
  <strong>Difficult</strong> - the kid is gone.
    </div>
    <div className="my-1 p-3 bg-gray-700 rounded text-white">
  <strong>Expert</strong> - the trailer is gone.
    </div>
    <br />
    <h3>{`5. Pictures and videos`}</h3>
    <br />
    <strong>Cover photo</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Pictures and videos are a very strong medium and have a high influence on
  decisions. The first picture you upload will be the cover picture for the
  trail.
    </div>
    <br />
    <strong>Picture quality</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  As far as you can, please upload only images of good quality that shows the
  character of the trail, riding, or surroundings. Using pictures of people
  riding their bike is preferrable.
    </div>
    <br />
    <strong>Landscape mode</strong>
    <div className="my-1 p-3 bg-gray-200 rounded">
  Please try to upload only landscape mode images. Vertical pictures do not work
  well in the user interface. If you do not know what landscape mode is, you
  should ask an adult.
    </div>
    <br />
    <br />
    <p>{`Thank you for helping us keep the quality of our content high. This will help a long way
for our users to find great experiences.`}</p>
    <br />
    <br />
    <p>{`With kind regards,`}<br />{`
The Trailguide Team`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      